import Layout from './components/layout';

import React from 'react';

const NotFound = () => {
  return (
    <Layout>
      <h2>Sorry not found</h2>
    </Layout>
  );
};

export default NotFound;
